@import "solar/Tokens.scss";

.card {
  position: relative;
  overflow: hidden;
  border-radius: $sol-space-xs;
  text-align: center;
  aspect-ratio: 4 / 6;

  @media (min-width: $sol-breakpoint-lg) {
    border-radius: $sol-space-sm;
    aspect-ratio: 4 / 6;
  }
}

.photo {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  width: 100%;
  height: 100%;
  background-color: $sol-color-neutral-1;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $sol-color-black;
  opacity: 0.2;
}

.content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  grid-template-rows: 1fr auto 1fr;
  width: 100%;
  height: 100%;
  color: $sol-color-white;
}

.location {
  font-weight: 500;
  font-size: 12px;
  font-family: $sol-font-family-mono;
  text-transform: uppercase;
}

.dates {
  font-weight: 300;
  font-size: 12px;
  margin-top: $sol-space-2xs;
}

.top {
  position: absolute;
  top: $sol-space-sm;
  right: 0;
  left: 0;
  @media (min-width: $sol-breakpoint-lg) {
    top: $sol-space-lg;
  }
}

.bottom {
  position: absolute;
  right: 0;
  bottom: $sol-space-sm;
  left: 0;
  @media (min-width: $sol-breakpoint-lg) {
    bottom: $sol-space-lg;
  }
}

.top,
.bottom {
  padding-right: $sol-space-xs;
  padding-left: $sol-space-xs;

  @media (min-width: $sol-breakpoint-lg) {
    padding-right: $sol-space-md;
    padding-left: $sol-space-md;
  }
}

.name {
  font-size: 24px;
  margin-top: $sol-space-md;
  word-spacing: 100vw;
  line-height: 100%;
  @media (min-width: $sol-breakpoint-lg) {
    font-size: 68px;
  }
}

.cta {
  display: inline-block;
  padding-bottom: 3px;
  border-bottom: 1px solid rgba($sol-color-white, 0.4);
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
}

.grayed {
  opacity: 0.4;
  filter: grayscale(40%);
}
