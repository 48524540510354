@import "solar/Tokens.scss";

.component {
  background-color: $sol-color-brown;
  color: $sol-color-off-white;
  text-align: center;
}

.quote {
  font-style: italic;
  line-height: 1.2;
}

.author {
  margin-top: $sol-space-xs;

  @media (min-width: $sol-breakpoint-lg) {
    margin-top: $sol-space-sm;
  }
}
