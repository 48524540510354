@import "solar/Tokens.scss";

$navbar-sm-offset: 66px;
$navbar-lg-offset: 66px;

.component {
  position: relative;
  overflow: hidden;
  padding-top: $sol-space-2xl + ($navbar-lg-offset / 2);
  padding-right: $sol-space-md;
  padding-bottom: $sol-space-xl;
  padding-left: $sol-space-md;
  background-color: $sol-color-forest;
  color: $sol-color-white;

  @media (min-width: $sol-breakpoint-lg) {
    padding-top: $sol-space-3xl + ($navbar-lg-offset / 2);
    padding-right: $sol-space-3xl;
    padding-bottom: $sol-space-3xl;
    padding-left: $sol-space-3xl;
  }
}

.backgroundPhoto {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.foreground {
  position: relative;
  z-index: 2;
  padding-top: $sol-space-xs;
  text-align: center;

  @media (min-width: $sol-breakpoint-lg) {
    padding-top: $sol-space-sm;
  }
}

.foregroundVisual {
  position: relative;
  object-fit: cover;
  overflow: hidden;
  margin-top: $sol-space-md;
  border-radius: $sol-space-xs;
  background-color: $sol-color-forest;
  aspect-ratio: 5/4;
  isolation: isolate;

  @media (min-width: $sol-breakpoint-lg) {
    margin-top: $sol-space-lg;
    border-radius: $sol-space-sm;
    aspect-ratio: 7/4;
  }
}

.videoLink {
  position: relative;
  display: block;
}

.videoPlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
