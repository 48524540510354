@import "solar/Tokens.scss";

.component {
  max-width: $sol-breakpoint-sm;
  margin: 0 auto;
  text-align: center;
}

.text {
  margin-top: $sol-space-sm;

  @media (min-width: $sol-breakpoint-lg) {
    margin-top: $sol-space-md;
  }
}
