@import "solar/Tokens.scss";

.component {
  background-color: $sol-color-neutral-1;
  color: $sol-color-black;
}

.grid {
  display: grid;
  row-gap: $sol-space-xs;
  margin-top: $sol-space-lg;

  @media (min-width: $sol-breakpoint-lg) {
    row-gap: $sol-space-md;
    margin-top: $sol-space-xl;
  }
}

.gridRow {
  display: grid;
  column-gap: $sol-space-xs;

  @media (min-width: $sol-breakpoint-lg) {
    column-gap: $sol-space-md;
  }
}

.gridRow1 {
  grid-template-columns: 1fr 2fr;
}

.gridRow2 {
  grid-template-columns: 2fr 1fr;
}

.photo {
  object-fit: cover;
  height: 100%;
}
