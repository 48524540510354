@import "solar/Tokens.scss";

.component {
  background-color: $sol-color-off-white;
}

.visuals {
  margin-top: $sol-space-lg;

  @media (min-width: $sol-breakpoint-lg) {
    margin-top: $sol-space-2xl;
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: $sol-space-sm;

  @media (min-width: $sol-breakpoint-lg) {
    column-gap: $sol-space-md;
  }
}

.gridRow {
  display: grid;
  grid-auto-rows: min-content;
  row-gap: $sol-space-sm;

  @media (min-width: $sol-breakpoint-lg) {
    row-gap: $sol-space-md;
  }
}

.overrides {
  :global {
    @import "swiper/css";

    .swiper {
      box-sizing: content-box;
    }

    .swiper-css-mode > .swiper-wrapper {
      box-sizing: border-box;
      padding-right: $sol-space-xs;
      padding-left: $sol-space-sm;
      scroll-padding-left: $sol-space-sm;
      scroll-padding-right: $sol-space-xs;
    }
  }

  .slide {
    padding-right: $sol-space-xs;
  }
}
