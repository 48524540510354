@import "solar/Tokens.scss";

.component {
  background-color: $sol-color-forest;
  color: $sol-color-off-white;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: $sol-space-md;
  margin-top: $sol-space-lg;
  column-gap: $sol-space-sm;

  @media (min-width: $sol-breakpoint-lg) {
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: $sol-space-lg;
    margin-top: $sol-space-2xl;
    column-gap: $sol-space-lg;
  }
}
