@import "solar/Tokens.scss";
$lqip_blur: 4px;

.widthUnknown {
  background: $sol-color-white;

  .imgWrapper {
    display: none;
  }
}

.imgWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.visual {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.poster {
  z-index: 1;
  opacity: 1;
  transition: opacity 200ms ease;
}

.hidden {
  opacity: 0;
}

.lqip {
  top: 0px;
  left: 0px;
  z-index: 2;
  width: 100%;
  height: 100%;
  filter: blur($lqip_blur);
  transition: opacity 200ms ease;
  transform: scale(1.035);
  will-change: transform; /* force hardware exceleration for Safari */
}
