@import "solar/Tokens.scss";

.card {
  position: relative;
  overflow: hidden;
  border-radius: $sol-space-xs;
  text-align: center;
  aspect-ratio: 4 / 6;

  @media (min-width: $sol-breakpoint-lg) {
    border-radius: $sol-space-sm;
    aspect-ratio: 4 / 6;
  }
}

.photo {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  width: 100%;
  height: 100%;
  background-color: $sol-color-neutral-1;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $sol-color-black;
  opacity: 0.2;
}

.content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  grid-template-rows: 1fr auto 1fr;
  width: 100%;
  height: 100%;
  color: $sol-color-white;
}

.play {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (min-width: $sol-breakpoint-lg) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.text {
  position: absolute;
  right: 0;
  bottom: $sol-space-sm;
  left: 0;
  width: 100%;
  padding-right: $sol-space-2xs;
  padding-left: $sol-space-2xs;

  @media (min-width: $sol-breakpoint-lg) {
    bottom: $sol-space-md;

    padding-right: $sol-space-sm;
    padding-left: $sol-space-sm;
  }
}

.name {
  margin-top: $sol-space-sm;
}

.location {
  margin-top: $sol-space-2xs;
  font-weight: 500;
  font-size: 11px;
  font-family: $sol-font-family-mono;
  text-transform: uppercase;

  @media (min-width: $sol-breakpoint-lg) {
    font-size: 12px;
  }
}
