@import "solar/Tokens.scss";

$grid-padding: $sol-space-lg;

.component {
  padding-right: $sol-space-xs;
  padding-left: $sol-space-xs;
  background-color: $sol-color-off-white;
  color: $sol-color-off-white;
  text-align: center;

  @media (min-width: $sol-breakpoint-lg) {
    padding-right: $sol-space-md;
    padding-left: $sol-space-md;
  }
}

.container {
  border-radius: $sol-border-radius-md;
  background-color: $sol-color-purple;

  @media (min-width: $sol-breakpoint-lg) {
    row-gap: $sol-space-2xl;
  }
}

.media {
  position: relative;
  overflow: hidden;
  margin-top: $sol-space-lg;
  border-radius: $sol-border-radius-md;
  aspect-ratio: 4/3;
  isolation: isolate;

  @media (min-width: $sol-breakpoint-lg) {
    margin-top: $sol-space-2xl;
    aspect-ratio: 16/9;
  }
}

.grid {
  display: grid;
  row-gap: $sol-space-md;
  margin-top: $sol-space-lg;
  padding-right: $sol-space-sm;
  padding-left: $sol-space-sm;

  @media (min-width: $sol-breakpoint-lg) {
    row-gap: 0;
    margin-top: $sol-space-2xl;
    padding-right: $sol-space-3xl;
    padding-left: $sol-space-3xl;
  }
}

.gridRow {
  display: grid;
  row-gap: $sol-space-md;

  @media (min-width: $sol-breakpoint-lg) {
    grid-template-columns: 1fr auto 1fr;
    row-gap: 0;
  }
}

.gridColumnDivider {
  width: 100%;
  height: 1px;
  background-color: rgba($sol-color-off-white, 0.2);

  @media (min-width: $sol-breakpoint-lg) {
    width: 1px;
    height: 100%;
  }
}

.gridRowDivider {
  width: 100%;
  height: 1px;
  background-color: rgba($sol-color-off-white, 0.2);
}

.gridItem1 {
  @media (min-width: $sol-breakpoint-lg) {
    padding-right: $grid-padding;
    padding-bottom: $grid-padding;
  }
}

.gridItem2 {
  @media (min-width: $sol-breakpoint-lg) {
    padding-bottom: $grid-padding;
    padding-left: $grid-padding;
  }
}

.gridItem3 {
  @media (min-width: $sol-breakpoint-lg) {
    padding-top: $grid-padding;
    padding-right: $grid-padding;
  }
}

.gridItem4 {
  @media (min-width: $sol-breakpoint-lg) {
    padding-top: $grid-padding;
    padding-left: $grid-padding;
  }
}

.description {
  margin-top: $sol-space-xs;

  @media (min-width: $sol-breakpoint-lg) {
    margin-top: $sol-space-sm;
  }
}
