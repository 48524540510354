@import "solar/Tokens.scss";

.component {
  padding-right: $sol-space-xs;
  padding-left: $sol-space-xs;
  background-color: $sol-color-navy;
  color: $sol-color-off-white;
  text-align: center;

  @media (min-width: $sol-breakpoint-lg) {
    padding-right: $sol-space-md;
    padding-left: $sol-space-md;
  }
}

.container {
  border-radius: $sol-border-radius-md;
  background-color: $sol-color-navy;

  @media (min-width: $sol-breakpoint-lg) {
    row-gap: $sol-space-2xl;
  }
}

.visual {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin-top: $sol-space-xl;
  padding: $sol-space-xs;
  aspect-ratio: 4/4;

  @media (min-width: $sol-breakpoint-lg) {
    margin-top: $sol-space-2xl;
    aspect-ratio: 16/9;
  }
}

.videoPlayer {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: $sol-border-radius-md / 2;
}

.button {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: $sol-z-subnav;
  display: inline-flex;
  justify-content: center;
  padding: $sol-space-sm $sol-space-md;
  border: 2px solid $sol-color-yellow;
  border-radius: $sol-border-radius-half;
  background-color: $sol-color-yellow;
  color: $sol-color-black;
  font-weight: 500;
  font-size: 16px;
  font-family: $sol-font-family;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  filter: $sol-shadow-dropdown;
  cursor: pointer;
  user-select: none;
  transform: translate(-50%, -50%);
  place-items: center;

  @media (min-width: $sol-breakpoint-lg) {
    display: inline-flex;
    padding: $sol-space-sm $sol-space-lg;
    font-size: 18px;
  }

  &:hover,
  &:active,
  &:focus {
    color: $sol-color-black;
  }
}
