@import "solar/Tokens.scss";

:export {
  sizeSwiperGapSm: strip-unit($sol-space-xs);
  sizeSwiperGapLg: strip-unit($sol-space-sm);
}

.component {
  background-color: $sol-color-forest;
  color: $sol-color-off-white;
  padding-bottom: $sol-space-xl;
  padding-top: $sol-space-xl;

  @media (min-width: $sol-breakpoint-lg) {
    padding-bottom: $sol-space-3xl;
    padding-top: $sol-space-3xl;
  }
}

.carousel {
  position: relative;
  margin-top: $sol-space-lg;

  @media (min-width: $sol-breakpoint-lg) {
    margin-top: $sol-space-2xl;
  }
}

.footer {
  margin-top: $sol-space-lg;
  text-align: center;

  @media (min-width: $sol-breakpoint-lg) {
    margin-top: $sol-space-xl;
  }
}

.button {
  display: block;
  justify-content: center;
  border: 2px solid transparent;
  background-color: transparent;
  font-weight: 500;
  font-family: $sol-font-family;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  place-items: center;
  border-color: $sol-color-off-white;
  background-color: transparent;
  color: $sol-color-off-white;
  padding: $sol-space-sm 0;
  border-radius: $sol-border-radius-half;
  font-size: 16px;

  &:hover,
  &:active,
  &:focus {
    color: $sol-color-off-white;
  }

  @media (min-width: $sol-breakpoint-lg) {
    font-size: 18px;
    display: inline-flex;
    padding: $sol-space-sm $sol-space-lg;
  }
}

.arrow {
  display: none;
  position: absolute;
  z-index: 9;
  top: 50%;
  color: $sol-color-black;
  background-color: rgba($sol-color-white, 0.85);
  padding: $sol-space-sm;
  border-radius: $sol-border-radius-half;

  @media (min-width: $sol-breakpoint-lg) {
    display: block;
  }
}

.previous {
  left: $sol-space-sm;
  transform: scaleX(-1) translateY(-50%);
}

.next {
  right: $sol-space-sm;
  transform: translateY(-50%);
}

.disabled {
  opacity: 0.25;
  cursor: not-allowed;
}

.lock {
  display: none;
}

.overrides {
  :global {
    @import "swiper/css";

    .swiper {
      box-sizing: content-box;

      @media (min-width: $sol-breakpoint-lg) {
        padding-left: $sol-space-lg;
        padding-right: $sol-space-lg;
      }
    }

    .swiper-css-mode > .swiper-wrapper {
      box-sizing: border-box;
      padding-left: $sol-space-sm;
      padding-right: $sol-space-sm - $sol-space-2xs;
    }
  } // :global

  .slide {
    width: 100%;

    @media (min-width: $sol-breakpoint-lg) {
      padding-right: $sol-space-2xs;
    }
  }
} // .overrides
